<template>
  <div class="flex items-center" :class="!!left || 'justify-center'">
    <span
      class="h-3 w-3 mr-1 block rounded-full"
      :class="classObject.dot"
    ></span>
    <p :class="classObject.label">
      <b>{{ status }}</b>
    </p>
  </div>
</template>

<script>
export default {
  props: ['status', 'danger', 'left'],
  computed: {
    classObject: function () {
      let classes = {
        label: [],
        dot: [],
      }
      switch (this.status) {
        case 'Recibida': {
          classes.label = ['text-green-500']
          classes.dot = ['bg-green-500']
          break
        }
        case 'Abierta':
          classes.label = ['text-yellow-300']
          classes.dot = ['bg-yellow-300']
          break
        case 'Abierta en Conciliación':
          classes.label = ['text-yellow-600']
          classes.dot = ['bg-yellow-500']
          break
        case 'Cerrada':
          classes.label = ['text-red-500']
          classes.dot = ['bg-red-500']
      }
      return classes
    },
  },
}
</script>

<style></style>
