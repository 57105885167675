<template>
  <div class="h-min-content max-w-2xl w-5/6">
    <div v-if="loading" class="flex justify-center gap-3 text-primary">
      <a-spin class="text-primary">
        <a-icon slot="indicator" type="loading" spin />
      </a-spin>
      <span class="mt-px">Cargando</span>
    </div>
    <div v-if="complaint" class="p-6 bg-white rounded-2xl shadow-lg mx-4">
      <div class="mt-4 sm:w-3/4">
        <ComplaintDetailHeaderCard
          :radicado="complaint.codigo_queja"
          detail="true"
          :status="complaint.estado_valor"
        />
      </div>
      <ComplaintDetail
        v-if="complaint && Object.keys(complaint).length > 0"
        :complaint="complaint"
      />
    </div>
  </div>
</template>

<script>
import ComplaintDetail from '@/components/Dashboard/Complaints/Complaint/ComplaintDetail'
import ComplaintDetailHeaderCard from '@/components/Dashboard/Complaints/Complaint/ComplaintDetailHeaderCard'
export default {
  components: {
    ComplaintDetailHeaderCard,
    ComplaintDetail,
  },
  async created() {
    const { complaint_id } = this.$route.params
    this.loading = true
    const response = await this.$api.complaints.getComplaintById(complaint_id)
    this.loading = false
    if (response.status !== 200) {
      this.$showNotification('error', response.data)
      return
    }
    this.complaint = response.data
  },
  data() {
    return {
      complaint: null,
    }
  },
}
</script>

<style></style>
