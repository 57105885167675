<template>
  <div
    class="w-full py-2 rounded-xl flex flex-col border-primary border-2 sm:flex-row justify-around items-center"
  >
    <div class="w-full py-2">
      <p>
        <b>{{ detail ? 'Radicado' : 'Señor' }}</b>
      </p>
      <p v-if="radicado">{{ radicado }}</p>
      <p v-if="name">{{ name }}</p>
    </div>
    <span class="block w-3/4 h-px sm:w-1 sm:h-16 bg-primary"></span>
    <div class="w-full py-2">
      <p>
        <b>{{ detail ? 'Estado' : 'Email' }}</b>
      </p>
      <p v-if="email">{{ email }}</p>
      <ComplaintStatusLabel v-if="status" :status="status" danger="true" />
    </div>
  </div>
</template>

<script>
import ComplaintStatusLabel from '@/components/Dashboard/Complaints/ComplaintStatusLabel'
export default {
  props: ['name', 'radicado', 'detail', 'status', 'email'],
  components: {
    ComplaintStatusLabel,
  },
}
</script>

<style></style>
