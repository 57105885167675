<template>
  <div class="w-full my-8">
    <h2 class="my-3 text-gray-600 text-left text-3xl"><b>Respuesta</b></h2>
    <div v-if="!complaint.fecha_actualizacion">
      <p class="text-left rounded-xl">
        ¿Desea iniciar proceso de respuesta ante el consumidor financiero?
      </p>
      <Button
        class="mt-3"
        :block="true"
        @click.native="$router.push({ path: 'response/', append: true })"
      >
        Empezar gestión de respuesta
      </Button>
    </div>
    <div v-else>
      <BaseInput
        v-if="complaint.aceptacion_queja_valor"
        label="Aceptación"
        :value="complaint.aceptacion_queja_valor"
        disabled
      />
      <BaseInput
        v-if="complaint.rectificacion_queja_valor"
        label="Rectificación"
        :value="complaint.rectificacion_queja_valor"
        disabled
      />
      <BaseInput
        v-if="complaint.desistimiento_queja_valor"
        label="Desistimiento"
        :value="complaint.desistimiento_queja_valor"
        disabled
      />
      <BaseInput
        v-if="complaint.admision_valor"
        label="Admisión"
        :value="complaint.admision_valor"
        disabled
      />
      <BaseInput
        v-if="complaint.tutela_valor"
        label="Tutela"
        :value="complaint.tutela_valor"
        disabled
      />
      <BaseInput
        v-if="complaint.queja_expres_valor"
        label="Queja express"
        :value="complaint.queja_expres_valor"
        disabled
      />
      <div v-if="complaint.argumento_replica">
        <h2 class="mt-5 text-gray-600 text-left text-3xl">
          <b>Réplica</b>
          <BaseTextarea
            ref="replica"
            id="replica"
            label="Argumetno de la réplica:"
            :value="complaint.argumento_replica"
            required="true"
            :disabled="true"
          />
        </h2>
      </div>
      <Button
        class="mt-5"
        :block="true"
        @click.native="$router.push({ path: 'response/', append: true })"
      >
        Gestionar respuesta
      </Button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['complaint'],
}
</script>

<style></style>
