<template>
  <div class="w-full px-0 py-2">
    <div v-if="complaint" class="w-full">
      <h2 class="my-5 text-md text-left text-primary">
        <b>Datos de contacto</b>
      </h2>
      <BaseRadio
        name="person-type"
        id="person-type"
        label="Tipo de persona"
        :radioOptions="formOptions.optionsPersonType"
        :value="complaint.tipo_persona"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Nombres"
        :value="complaint.nombres"
        :disabled="true"
      />
      <BaseSelect
        label="Tipo de identificación"
        id="identification"
        :value="complaint.tipo_id_CF"
        :selectOptions="formOptions.optionsIdentificationType"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Número de identificación"
        placeholder="Ej:123456789"
        :value="complaint.numero_id_CF"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Correo electrónico"
        :value="complaint.correo"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Teléfono"
        :value="complaint.telefono"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Dirección"
        :value="complaint.direccion"
        :disabled="true"
      />
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 md:gap-4">
      <BaseSelect
        v-if="complaint.sexo"
        id="sexo"
        label="Sexo"
        :selectOptions="formOptions.optionsGender"
        :value="complaint.sexo"
        :disabled="true"
      />
      <BaseRadio
        v-if="complaint.lgbtiq"
        name="LGTBIQ+"
        label="LGTBIQ+"
        :radioOptions="formOptions.optionsYN"
        :value="complaint.lgbtiq"
        :disabled="true"
      />
    </div>
    <div class="w-full">
      <BaseInput
        v-if="complaint.condicion_especial !== 98"
        id="special-condition"
        type="text"
        label="Condición especial"
        :value="complaint.condicion_especial_valor"
        :disabled="true"
      />
    </div>
    <div v-if="complaint" class="w-full">
      <h2 class="my-5 text-md text-left text-primary">
        <b>Detalle de la queja</b>
      </h2>
      <BaseInput
        v-if="complaint"
        type="text"
        id="abroad"
        label="Inconformidad que motiva su queja ocurrió el exterior"
        v-model="abroad"
        :disabled="true"
      />
      <div v-if="abroad == 'Si'">
        <BaseInput
          v-if="complaint"
          type="text"
          id="country"
          label="País donde ocurrio la incidencia"
          :value="complaint.pais_valor"
          :disabled="true"
        />
      </div>
      <div v-if="abroad == 'No'">
        <BaseInput
          v-if="complaint"
          type="text"
          id="department"
          label="Departamento donde ocurrio la incidencia"
          :value="complaint.departamento_valor"
          :disabled="true"
        />
        <BaseInput
          v-if="complaint"
          type="text"
          id="city"
          label="Municipio donde ocurrio la incidencia"
          :value="complaint.municipio_valor"
          :disabled="true"
        />
      </div>
      <BaseInput
        v-else
        type="text"
        label="País"
        :value="complaint.pais_valor"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="La inconformidad está relacionada con alguno de los siguientes productos"
        :value="complaint.producto_valor"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Motivo de su inconformidad"
        :value="complaint.motivo_valor"
        :disabled="true"
      />
      <BaseTextarea
        label="Detalle del producto"
        placeholder="Descripción"
        :value="complaint.producto_nombre"
        :disabled="true"
        maxlength="100"
      />
      <BaseInput
        type="text"
        label="Canal"
        :value="complaint.canal_valor"
        :disabled="true"
      />
      <BaseTextarea
        ref="describe"
        id="describe"
        label="Describa los hechos brevemente:"
        placeholder="Mensaje"
        :value="complaint.texto_queja"
        required="true"
        :disabled="true"
        maxlength="1000"
      />
      <div
        v-if="complaint.files && complaint.files.length > 0"
        class="mt-4 flex flex-col"
      >
        <p class="mb-3 text-xs text-left font-bold text-gray-500">Anexos</p>
        <BaseDownload
          v-for="(file, key) in complaint.files"
          :value="file.filename"
          :key="key"
          :url_download="file.storage_full_path"
        />
      </div>
      <Button
        v-if="complaint.prorroga_queja < 13"
        class="mt-8"
        styles="secondary"
        :block="true"
        :invert="true"
        :loading="loadingExtension"
        :disable="loadingExtension"
        @click.native="requestExtension"
      >
        Solicitar prórroga
      </Button>
      <Button
        class="mt-8"
        :danger="true"
        :block="true"
        :invert="true"
        @click.native="$router.go(-1)"
      >
        Volver
      </Button>
    </div>
    <ComplaintDetailResponse :complaint="complaint" />
  </div>
</template>

<script>
import ComplaintDetailResponse from '@/components/Dashboard/Complaints/Complaint/ComplaintDetailResponse'
import { mapState } from 'vuex'
export default {
  props: ['complaint'],
  components: {
    ComplaintDetailResponse,
  },
  data() {
    return {
      loadingExtension: false,
    }
  },
  computed: {
    abroad: function () {
      if (this.complaint.codigo_pais != '170') {
        return 'Si'
      } else {
        return 'No'
      }
    },
    ...mapState({
      formOptions: 'form',
    }),
  },
  methods: {
    async requestExtension() {
      this.loadingExtension = true
      const response = await this.$api.complaints.getComplaintExtension(
        this.complaint.codigo_queja
      )
      this.loadingExtension = false
      if (response.status !== 200) {
        this.$showNotification(
          'error',
          'No se pudo obtener la solictud de prorroga de la queja'
        )
        return
      }

      this.$showNotification('success', 'Se completo la solictud de prorroga')
    },
  },
}
</script>

<style></style>
